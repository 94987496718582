<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <h3>REPORTES</h3>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>REPORTES</v-breadcrumbs-item>
          <v-spacer></v-spacer>
        </template>
      </v-breadcrumbs>
    </template>

    <template>
      <v-card class="overflow-hidden mt-2">
        <v-toolbar flat color="white">
          <v-toolbar-title class="font-weight-bold" color="grey darken-3">
            REPORTE GRÁFICO
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-col cols="12" md="6" lg="6">
          <span class="green--text">Filtrar por Territorio</span>
          <v-autocomplete
            dense
            solo
            placeholder="Seleccione un valor"
            :items="itemsTerritory"
            item-text="descripcion"
            item-value="territorio_id"
            v-model="territory_id"
            @input="fillData"
          >
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-row v-for="(item, index) in dataJson" :key="index">
            <v-col cols="6" md="6" lg="6">
              <v-card max-width="600" outlined>
                <span class="font-weight-medium ml-2"
                  >NÚMERO DE HECTÁREAS EN
                </span>
                <h1 class="font-weight-medium text-center display-4 mt-4">
                  {{ item.total_hectare }}
                </h1>
                <h4 class="text-center mb-4">TOTAL DE HECTÁREAS</h4>

                <v-divider inset class="mb-10 mt-10"></v-divider>
                <h1
                  class="font-weight-medium text-center display-4 mt-4 green--text"
                >
                  {{ item.productive_hectare }}
                </h1>
                <h4 class="text-center mb-4">HECTÁREAS PRODUCTIVAS</h4>
              </v-card>
            </v-col>
            <v-col cols="6" md="6" lg="6">
              <v-card max-width="600" outlined>
                <span class="font-weight-medium"
                  >PORCENTAJE POR TIPOS DE ÁREAS EN
                </span>
                <cases-by-state :queryStats="territory_id"></cases-by-state>
              </v-card>
            </v-col>
            <v-col cols="6" md="6" lg="6">
              <v-card max-width="600" outlined>
                <span class="font-weight-medium"
                  >HECTÁREAS POR TIPOS DE ÁREAS EN
                </span>
                <cases-by-territory
                  :queryStats="territory_id"
                ></cases-by-territory>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-divider class="mb-2 mt-2"></v-divider>
        <template>
          <h4 class="ml-2">ÁREAS DEMARCADAS EN</h4>
          <div class="mt-5" style="height: 500px; width: 100%" id="map"></div>
        </template>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import { sweetalert, Swal } from "sweetalert2";
import { mapState } from "vuex";

import CasesByTerritory from "./reports/CasesByTerritory.vue";
import CasesByState from "./reports/CasesByState.vue";
import { listGeoCapasData, listGeoLayerMap } from "@/services/Planning";

export default {
  components: { CasesByTerritory, CasesByState },
  name: "graphicReports.vue",
  data() {
    return {
      territory_id: null,
      itemsTerritory: [],
      dataJson: [],
      dataJsonMap: [],
      totalHectare: 0,
      productiveHectare: 0,
      fileJson: [],
      map:''
    };
  },
  methods: {
    async fillData() {
      /**
     * [ trae los parametros enviados desde listGeocapas]
     * @param {[this.dataJson]} dataJson [data con las coordenadas del territorio]
     * @param { [this.dataJsonMap] } dataJsonMap [geolayer map con los mapas]
     */
      this.dataJson= [];
      this.dataJsonMap= [];
      try {
        this.showLoader();
        const dataJson = await listGeoCapasData(this.territory_id);
        this.dataJson = dataJson.data;

        const dataJsonMap = await listGeoLayerMap(this.territory_id);
        this.dataJsonMap = dataJsonMap.data;

        this.hideLoader();
      } catch (err) {
        this.hideLoader();
        console.error(err);
        Swal("Error", "ha ocurrido un error al procesar la solicitud", "error");
      }

    //  for(let i in this.map._layers) {
    //     if(this.map._layers[i]._path != undefined) {
    //         try {
    //             this.map.removeLayer(this.map_layers[i]);
    //         }
    //         catch(e) {
    //             console.log("problem with " + e + this.map._layers[i]);
    //         }
    //     }
    // }
      var street = this.dataJsonMap.map((element) => {
        return element.features;
      });

       for(let i in this.map._layers) {
        if(this.map._layers[i]._path != undefined) {
            try {
                this.map.removeLayer(this.map._layers[i]);
            }
            catch(e) {
                console.log("problem with " + e + this.map._layers[i]);
            }
        }
    }
    // function getColor(d) {
    // return d == "rojo"   ? 'red' :
    //        d == "amar"   ? 'yellow' :
    //        d == "verde"   ? 'green' :'#6BB2E4';
    //         }
    // function style(d) {
    //   console.log("colorr "+d)
    // return {
    //     color: getColor(d),
    //     weight: 2,
    //     fillOpacity: 1
    //   };
    // }
      var s_light_style = {
        showArea: false,
        fillColor: "#ff5800",
        color: "#000",
        weight: 1,
        opacity: 1,
        fillOpacity: 0.8,
        shapeOptions: {
          stroke: true,
        },
      };
      window.L.geoJSON(street, {

        // onEachFeature: function (feature, layer) {

        //   var popupContent ="";
        //   //   '<h4 class = "text-primary">Otros Datos</h4>' +
        //   //   '<div class="container"><table class="table table-striped">' +
        //   //   "<thead><tr><th>Dato</th><th>Valor</th></tr></thead>" +
        //   //   "<tbody><tr><td> Name </td><td>" +
        //   //   JSON.stringify(feature.properties.color) +
        //   //   "</td></tr>" +
        //   //   "<tr><td>Hectárea </td><td>" +
        //   //   feature.properties.hectarea +
        //   //   "</td></tr>" +
        //   //   "<tr><td> Time </td><td>" +
        //   //   feature.properties.time +
        //   //   "</td></tr>";
        //    layer.bindPopup(popupContent);
        // },

        pointToLayer: function (feature, latlng) {

          return window.L.circleMarker(latlng, { style: s_light_style });
        },
        // style:function (feature){
        //   return style( feature.properties.color)
        // }
      }).addTo(this.map);
    },
  },

  computed: {
    ...mapState(["dataSource", "user", "permissions"]),
  },
  async created() {
    /**
     * [Se obtienen los territorios]
     * @param {[this.itemsTerritory]} territorios [Territorios]
     */
    try {
      this.itemsTerritory = await this.dataSource.data.Territorio;
    } catch (err) {
      this.hideLoader();
      sweetalert.fire(
        "Ups!",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
  async mounted() {
    /**
     * [Se inicializa el map en las coordenadas predeterminadas]
     * @param {[this.map]} Map [Mapa]
     */
      this.map = window.L.map("map").setView([2.778136, -76.323233], 10);
      window.L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(this.map);
  },
};
</script>

<style scoped>
.container-filter {
  display: flex;
  align-items: center;
  gap: 5px;
}
.leaflet-pane > svg.leaflet-interactive path {
  stroke: red;
}
</style>
