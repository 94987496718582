<template>
  <main style="width: 100%; overflow-x: auto;">
    <div class="wrapper" :style="{width: reportData.length <= 10 ? '100%' : `${(reportData.length * 30)}px`}">
      <canvas id="CasesByTerritory"></canvas>
    </div>
  </main>
</template>
<script>
import Chart from "chart.js/auto";
import { listGeoCapasData } from "@/services/Planning";


export default {
  props: ["queryStats"],
  data() {
    return {
      territories: [],
      reportData: [],
    };
  },
  methods: {
    async loadReportData() {
      const dataJson = await listGeoCapasData(this.queryStats);
      this.reportData = dataJson.data.data.data;

      this.$nextTick(() => this.renderBar());
    },

    renderBar() {
      const CasesByTerritory_bar = document.getElementById(
        "CasesByTerritory"
      );

      let label = [];
      let datos = [];

      this.reportData.forEach((item, position) => {
        label[position] = item.zone || "";
        datos[position] = item.area || 0;
      });

      new Chart(CasesByTerritory_bar, {
        type: "bar",
        data: {
          labels: label,
          datasets: [
            {
              label: "DATOS",
              data: datos,
              fill: false,
              backgroundColor: ["rgb(67, 121, 78, 1)", "rgb(20, 40, 102, 1)","rgb(242, 151, 46,1)","rgb(190, 195, 199,1) "],
              borderColor: "rgb(0, 128, 55,0.2)",
              pointBackgroundColor: "rgb(0, 128, 55)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgb(0, 128, 0)",
            },
          ],
        },
        options: {
          indexAxis: "x",
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            line: {
              borderWidth: 2,
            },
          },
        },
      });
    },
  },
  beforeMount() {
    this.loadReportData();
  }
};
</script>
<style scoped>
.wrapper {
  width: 100%;
  height: 450px;
  max-height: 650px;
  text-align: center !important;
}
</style>
